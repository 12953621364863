import { Card, CardActions, CardContent, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { UserModel } from 'core/models/userModel'
import { Trans } from 'gatsby-plugin-react-i18next'
import SkeletonWrapper from 'components/common/skeletonWrapper'
import SubmitButton from 'components/common/submitButton'
import { Link } from 'gatsby'

interface Props {
    loading: boolean
    user?: UserModel
}

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    fontWeight: 500,
    paddingBottom: theme.spacing(),
})) as typeof Typography

const AccountView: React.FC<Props> = ({ user, loading }) => {
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Title>
                            <Trans i18nKey="form.username" />
                        </Title>
                        <SkeletonWrapper loading={loading}>
                            <Typography>{user?.username}</Typography>
                        </SkeletonWrapper>
                    </Grid>
                    <Grid item xs={12}>
                        <Title>
                            <Trans i18nKey="form.email" />
                        </Title>
                        <SkeletonWrapper loading={loading}>
                            <Typography>{user?.email}</Typography>
                        </SkeletonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <Title>
                            <Trans i18nKey="form.givenName" />
                        </Title>
                        <SkeletonWrapper loading={loading}>
                            <Typography>{user?.givenName}</Typography>
                        </SkeletonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <Title>
                            <Trans i18nKey="form.familyName" />
                        </Title>
                        <SkeletonWrapper loading={loading}>
                            <Typography>{user?.familyName}</Typography>
                        </SkeletonWrapper>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <SubmitButton component={Link} variant="contained" to="/">
                    <Trans i18nKey="actions.previous" />
                </SubmitButton>
                <SubmitButton
                    component={Link}
                    sx={{ width: 160 }}
                    variant="contained"
                    color="secondary"
                    to="/mon-compte/modifier.html"
                >
                    <Trans i18nKey="actions.update" />
                </SubmitButton>
            </CardActions>
        </Card>
    )
}

export default AccountView
