import React from 'react'
import { graphql } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import withUser from 'containers/hoc/withUser'
import Main from 'containers/common/main'
import { Container, Typography } from '@mui/material'
import { Trans } from 'gatsby-plugin-react-i18next'
import AccountView from 'components/views/account/accountView'
import withAuthentication, { WithAuthenticationProps } from 'containers/hoc/withAuthentication'

interface Props extends RouteComponentProps, WithAuthenticationProps {}

const Account: React.FC<Props> = ({ isLoading, user }) => {
    return (
        <Main>
            <Container maxWidth="sm" sx={{ minHeight: 'calc(100vh - 64px)', paddingBottom: 4 }}>
                <Typography variant="h1" sx={{ marginBottom: 4 }}>
                    <Trans i18nKey="account.index.title" />
                </Typography>
                <AccountView loading={isLoading} user={user} />
            </Container>
        </Main>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
export default withAuthentication(withUser(Account))
